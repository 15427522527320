.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.blinking {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}

.hg-theme-default {
  background-color: white;
}

.react-simple-keyboard > div > div > div {
  text-transform: uppercase;
  font-size: 1.4em;
}

.react-simple-keyboard > div > div > div > span {
}

.word-input {
  border: 1px solid #ccc;
  background: linear-gradient(to left, #ccc 1px, white 0);
  /*  background-size: 0.9em 1px;*/
  color: #4444aa;
  font: 1.1em monaco, monospace;
  font-weight: 600;
  letter-spacing: 0.12em;
  text-indent: 0.15em;
  text-transform: uppercase;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
